.menu {
    display: inline-block;
    xfont-size: 14px;
    box-sizing: border-box;

}
.menu ul  {
    width: 178px;
    xheight: 276px;
    margin: 0;
    padding: 0px 0px;
    background: #FFFFFF;
    border: 1px solid #ECEAE6;
    xbox-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
    box-shadow: 0 4px 4px #00000025, 0 -1px 2px #0000000a;
    border-radius: 10px;
    list-style: none;
    box-sizing: border-box;
    overflow: hidden;
}

.menu ul li {
    width: 100%;
    padding: 0 20px;
    height: 48px;
    font-family: var(--font-family), sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    margin: auto;
    display: flex;
    color: #000000;
    text-decoration: none;
    box-sizing: border-box;
    align-items: center;
}

.menu li:hover {
    cursor: pointer;
    background-color: rgb(240,240,240);
}


.icn {
    width: 24px;
    height: 24px;
    display: block;
    margin-right: 10px;
}
/*
.icn-da {
    background: #000 center/cover url('../assets/images/Dashboard.png') no-repeat;
}
.icn-ca {
    background: #000 center/cover url('../assets/images/Candidates.png') no-repeat;
}
.icn-cl {
    background: #000 center/cover url('../assets/images/Clients.png') no-repeat;
}
.icn-po {
    background: #000 center/cover url('../assets/images/Positons.png') no-repeat;
}
.icn-no {
    background: #000 center/cover url('../assets/images/Notify.png') no-repeat;
}
*/
.menu li.selected {
    background-color: var(--accent-color);
    color: #FFFFFF;
}

.menu li.selected svg path {
    fill: white !important;
}

.menu li.selected .positions svg path {
    fill: transparent !important;
    stroke: white;
    stroke-width: 2;
}